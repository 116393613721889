import { Typography } from "@mui/material";
import { useAtomValue } from "jotai";
import React from "react";
import { darkModeAppbarAtom } from "../../../../features/case_study/angeleye/atoms/darkModeAppbarAtom";
import { usePage } from "../../../../layouts";
import { language } from "../../../../traductions";
import { LocalizedSwitch } from "../../../LocalizedLink";
const locales = require("../../../../locales");

const LanguageButton = ({ whitened }: { whitened?: boolean }) => {
  const page = usePage();
  // const pageLocale = page.locale;
  const pageLocale = language.code || page.locale;

  const langs = Object.keys(locales);

  const { darkMode: darkModeAppbar } = useAtomValue(darkModeAppbarAtom);

  return (
    <Typography
      sx={{
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: 700,
        userSelect: "none",
        position: "relative",
      }}
    >
      <LocalizedSwitch style={{ textDecoration: "none" }}>
        {langs.map((lang, index) => (
          <React.Fragment key={lang}>
            <Typography
              component="span"
              sx={{
                // whiteSpace: "nowrap",
                fontSize: "inherit",
                lineHeight: "inherit",
                cursor: pageLocale === lang ? undefined : "pointer",
                fontWeight: pageLocale === lang ? 700 : 400,
                color:
                  whitened && pageLocale === lang
                    ? "#fff"
                    : whitened && pageLocale !== lang
                      ? "#ffffff70"
                      : pageLocale === lang
                        ? darkModeAppbar
                          ? "#fff"
                          : "#000"
                        : darkModeAppbar
                          ? "#ffffff70"
                          : "#00000070",
              }}
            >
              {index === 0 ? "" : " / "} {lang}
            </Typography>
          </React.Fragment>
        ))}
      </LocalizedSwitch>
    </Typography>
  );
};

export default LanguageButton;
