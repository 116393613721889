/** @jsxImportSource @emotion/react */
import { Theme, ThemeProvider } from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import { language } from "../traductions";
import "./layout.css";
import theme from "./theme";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useAtomValue } from "jotai";
import Topbar from "../components/shared/Topbar/Topbar";
import { darkModeAppbarAtom } from "../features/case_study/angeleye/atoms/darkModeAppbarAtom";
gsap.registerPlugin(ScrollTrigger);

//@ts-ignore
declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export interface ILocation {
  hash: string;
  host: string; // "192.168.1.1:8000"
  hostname: string; // "localhost"
  href: string; // "http://localhost:8000/it/"
  key: string; // "initial"
  origin: string; //"http://localhost:8000"
  pathname: string; // "/it/"
  port: string; // "8000"
  protocol: string; //"http:"
  search: string; // ""
  state: string; // null
}

const pageContext = React.createContext({
  locale: "en",
  location: {} as ILocation,
});
export const PageProvider = pageContext.Provider;
export const PageConsumer = pageContext.Consumer;
export const usePage = () => useContext(pageContext);

const Layout = (props: {
  location: ILocation;
  pageContext: { locale: string };
  children: React.Component<any, any, any>;
}) => {
  const { location, pageContext } = props;
  const containerRef = useRef(null);

  const { darkMode: darkModeAppbar } = useAtomValue(darkModeAppbarAtom);

  language.code = props.pageContext?.locale?.substring(0, 2) || "en";

  return (
    // <StylesProvider injectFirst>
    // <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <PageProvider value={{ locale: pageContext.locale, location }}>
        {/* <LocomotiveScrollProvider
              options={{
                // smooth: true,
                getDirection: true,
              }}
              watch={[location.pathname]}
              containerRef={containerRef}
            > */}

        {/* <ScrollTriggerProxy location={location.pathname} /> */}

        {/* <Header /> */}
        <Topbar />
        <main
          data-scroll-container
          ref={containerRef.current}
          className="main-scaffold-layout"
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            overflow: darkModeAppbar ? "initial" : "hidden",

            WebkitTapHighlightColor: "transparent",
            minHeight: "100vh",
            // backgroundColor: "rgba(0, 0, 255, 0.1)"
            // backgroundColor: rgbaArrayToString(
            // 	blendColors(
            // 		rgbaStringToArray('rgba(255, 255, 255, 1)'),
            // 		rgbaStringToArray('rgba(232, 83, 34, 0.03)')
            // 	)
            // ),
            background:
              "linear-gradient(0deg, rgba(232, 83, 34, 0.03), rgba(232, 83, 34, 0.03)), #FFFFFF;",
            transition: "background-color 300ms",
          }}
        >
          <AnimatedBody children={props.children} location={location} />

          {/* {props.children} */}
        </main>
        {/* </LocomotiveScrollProvider> */}
      </PageProvider>
    </ThemeProvider>
    // </StyledEngineProvider>
    // </StylesProvider>
  );
};

export default Layout;

const AnimatedBody = (props: {
  children: React.Component;
  location: ILocation;
}) => {
  const { darkMode: darkModeAppbar } = useAtomValue(darkModeAppbarAtom);

  return (
    <div
      style={{
        overflow: darkModeAppbar ? "initial" : "hidden",
        // overflowX: "hidden",
        minHeight: 200,
        width: "100%",
        flexGrow: 1,
        // marginTop: -headerDimensions.totHeight,
        // paddingTop: headerDimensions.totHeight
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div css={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        {props.children}
        {/* <Transition location={props.location} children={props.children} /> */}
      </div>
    </div>
  );
};

const ScrollTriggerProxy = (props: { location: string }) => {
  const { scroll } = useLocomotiveScroll();

  useEffect(() => {
    if (scroll) {
      const element = scroll?.el;

      scroll.on("scroll", ScrollTrigger.update);
      ScrollTrigger.scrollerProxy(element, {
        scrollTop(value) {
          return arguments.length
            ? scroll.scrollTo(value, 0, 0)
            : scroll.scroll.instance.scroll.y;
        },
        getBoundingClientRect() {
          return {
            top: 0,
            left: 0,
            width: window.innerWidth,
            height: window.innerHeight,
          };
        },
        pinType: element.style.transform ? "transform" : "fixed",
      });

      //? horizontal-scroll + pin-wrap
      let horizontalSections = document.querySelectorAll(".horizontal-scroll");

      horizontalSections.forEach((horizontalSection) => {
        let pinWrap = horizontalSection.querySelector(".pin-wrap") as any;
        let pinWrapWidth = pinWrap.offsetWidth;
        let horizontalScrollLength = pinWrapWidth - window.innerWidth;
        gsap.to(pinWrap, {
          scrollTrigger: {
            scroller: "[data-scroll-container]",
            scrub: true,
            trigger: horizontalSection,
            pin: true,
            start: "top top",
            end: () => `+=${pinWrapWidth}`,
            invalidateOnRefresh: true,
          },
          x: -horizontalScrollLength,
          ease: "none",
        });
      });
    }

    const scrollUpdate = () => scroll?.update();

    ScrollTrigger.addEventListener("refresh", scrollUpdate);

    return () => {
      ScrollTrigger.removeEventListener("refresh", scrollUpdate);
      ScrollTrigger.refresh();
    };
  }, [scroll, props.location]);

  return null;
};
