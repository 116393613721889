function GetInTouchIcon(props: {
  color: string;
  svgProps?: React.SVGProps<SVGSVGElement>;
}) {
  return (
    <svg
      width={15}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props.svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.327 5.736L6.864 1.273 8.136 0l6 6 .637.636-.637.637-6 6L6.864 12l4.463-4.464H0v-1.8h11.327z"
        fill={props.color}
      />
    </svg>
  );
}

export default GetInTouchIcon;
