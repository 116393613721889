import { atom } from "jotai";

type DarkModeAppbarAtomValue = {
  darkMode: boolean;
  backgroundColor?: string;
};

export const darkModeAppbarAtom = atom<DarkModeAppbarAtomValue>({
  darkMode: false,
});
