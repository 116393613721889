/** @jsxImportSource @emotion/react */
import { Box } from "@mui/material";
import { animated, easings, useSpring } from "@react-spring/web";
import { StaticImage } from "gatsby-plugin-image";
import { useAtomValue } from "jotai";
import { useEffect, useRef, useState } from "react";
import { darkModeAppbarAtom } from "../../../features/case_study/angeleye/atoms/darkModeAppbarAtom";
import { LocalizedLink } from "../../LocalizedLink";
import Menu, { MenuSize } from "../Menu/Menu";
import GetInTouchButton from "./components/GetInTouchButton";
import LanguageButton from "./components/LanguageButton";

const Topbar = () => {
  const [isOnTop, setIsOnTop] = useState(true);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const lastOpenCloseMillis = useRef(-1);

  const { darkMode: darkModeAppbar, backgroundColor } =
    useAtomValue(darkModeAppbarAtom);

  useEffect(() => {
    let scrollEL = () => {
      if (window.scrollY > 20) {
        setIsOnTop(false);
      } else {
        setIsOnTop(true);
      }
    };

    addEventListener("scroll", scrollEL);

    lastOpenCloseMillis.current = new Date().getTime();

    return () => {
      removeEventListener("scroll", scrollEL);
    };
  }, []);

  const AnimatedBox = animated(Box);
  const propsBox = useSpring({
    opacity: isOnTop ? 1 : 0,
    config: { duration: 400, easing: easings.easeInOutQuint },
  });

  function setMenuOpen(open: boolean) {
    if (lastOpenCloseMillis.current === -1) {
      // still initializing
      return;
    }

    const currentMillis = new Date().getTime();

    if (currentMillis - lastOpenCloseMillis.current > 400) {
      setIsMenuOpen(open);
      lastOpenCloseMillis.current = currentMillis + 500;
    }
  }

  return (
    <Box
      sx={{
        background: darkModeAppbar
          ? (backgroundColor ?? "rgba(255, 255, 255, 0)")
          : "linear-gradient(0deg, rgba(232, 83, 34, 0.03), rgba(232, 83, 34, 0.03)), rgba(255, 255, 255, .8);",
        position: "static",
      }}
    >
      <Box
        sx={{
          width: "100%",
          zIndex: 9300,
        }}
      >
        <Box
          sx={{
            display: "flex",
            margin: "0 auto",
            justifyContent: "space-between",
            alignItems: "center",
            padding: {
              md: `${MenuSize.padding.md}px`,
              xs: `${MenuSize.padding.xs}px`,
            },
          }}
        >
          <LocalizedLink to={"/"}>
            <AnimatedBox
              style={{ ...propsBox }}
              sx={{ width: "138px", objectFit: "contain" }}
            >
              {darkModeAppbar ? (
                <StaticImage
                  src={"./images/Logo_white.png"}
                  alt="mabiloft"
                  css={{}}
                />
              ) : (
                <StaticImage
                  src={"./images/Logo.png"}
                  alt="mabiloft"
                  css={{}}
                />
              )}
            </AnimatedBox>
          </LocalizedLink>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              height: "47px",
              // border: "1px solid green",
              boxSizing: "border-box",
              gap: "40px",
            }}
          >
            {/* <AnimatedBox
              style={{ ...propsBox }}
              sx={{
                display: { md: "flex", xs: "none" },
              }}
            >
              <WeAreHiring />
            </AnimatedBox> */}

            <AnimatedBox
              style={{ ...propsBox }}
              sx={{
                display: { md: "flex", xs: "none" },
              }}
            >
              <GetInTouchButton />
            </AnimatedBox>

            <AnimatedBox style={{ ...propsBox }}>
              <LanguageButton />
            </AnimatedBox>

            <Menu isOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Topbar;
